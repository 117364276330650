import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage-helper/local-storage-helper';
import { AppData } from 'types/app';
import { GuestType, IAppGeneralFeatures } from 'types/appFeatures';

export function getAppAlias(): string | undefined {
  return window.location?.pathname.split('/')[1];
}

/**
 * Util used on initial app load to locally store values to be kept
 * between refreshs.
 */
export function locallyStoreAppDataValues(appData: AppData) {
  setLocalStorage('firebaseConfig', appData.firebaseConfig);
  setLocalStorage('appName', appData.appName);
  setLocalStorage('appId', appData.appId);
  setLocalStorage('appFeatures', appData.features);
  setLocalStorage('links', appData.links);
}

export function getAppGuestType(): GuestType | undefined {
  const appFeatures = getLocalStorage<IAppGeneralFeatures>('appFeatures');
  if (!appFeatures) return undefined;
  return appFeatures.guestType;
}

export function isAppWithRequiredSignIn(): boolean {
  const guestType = getAppGuestType();
  return guestType === 'REGISTERED';
}

export function isAppWithSignInFeature(): boolean {
  const guestType = getAppGuestType();
  return guestType === 'REGISTERED' || guestType === 'REGISTERED_AND_ANONYMOUS';
}

export function isAppWithOptionalSignIn(): boolean {
  const guestType = getAppGuestType();
  return guestType === 'REGISTERED_AND_ANONYMOUS';
}
