import { createContext, useContext, useMemo, useReducer } from 'react';

interface ICookieModalStore {
  /**
   * Used to indicate wether to show a cookie info modal in ui or not.
   * Initially undefined as to identify it as 'never opened'.
   */
  readonly isCookieModalOpen: boolean | undefined;
}

const initialState: ICookieModalStore = {
  isCookieModalOpen: undefined,
};

enum Action {
  SET_OPEN,
  SET_CLOSED,
}

interface ICookieModalAPI {
  setCookieModalOpen: () => void;
  setCookieModalClosed: () => void;
}

const CookieModalState = createContext<ICookieModalStore>({
  isCookieModalOpen: undefined,
});

const CookieModalAPI = createContext<ICookieModalAPI>({} as ICookieModalAPI);

const reducer = (
  state: ICookieModalStore,
  action: Action,
): ICookieModalStore => {
  switch (action) {
    case Action.SET_OPEN:
      return {
        isCookieModalOpen: true,
      };
    case Action.SET_CLOSED:
      return {
        isCookieModalOpen: false,
      };
    default:
      return state;
  }
};

export function CookieProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: ICookieModalStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: ICookieModalAPI = useMemo(() => {
    const setCookieModalOpen = () => dispatch(Action.SET_OPEN);
    const setCookieModalClosed = () => dispatch(Action.SET_CLOSED);
    return { setCookieModalOpen, setCookieModalClosed };
  }, []);

  return (
    <CookieModalAPI.Provider value={api}>
      <CookieModalState.Provider value={state}>
        {children}
      </CookieModalState.Provider>
    </CookieModalAPI.Provider>
  );
}

export const useCookieModalStore = (): ICookieModalStore =>
  useContext(CookieModalState);
export const useCookieModalAPI = (): ICookieModalAPI =>
  useContext(CookieModalAPI);
