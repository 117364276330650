import { prefixKey } from 'helpers/storage-helper/storage-helper';

type LocalStorageKey =
  | 'userId'
  | 'appId'
  | 'appName'
  | 'appFeatures'
  | 'links'
  | 'accessToken'
  | 'firebaseConfig'
  | 'placeTimezoneName'
  | 'preferredPaymentMethod' // deprecated
  | 'preferredPayment'
  | 'i18nextLng'
  | 'testThemeName'
  | 'resolvedAppLink'
  | 'signInLinkEmail'
  | 'hasSeenSignIn'
  | '[testalias]accessToken'; // Used by Cypress

export function getLocalStorage<T>(key: LocalStorageKey): T | null;
export function getLocalStorage<T>(key: LocalStorageKey, otherwise: T): T;
export function getLocalStorage<T>(key: LocalStorageKey): T | null {
  const localStorageKey = prefixKey(key);
  const data: string | null = window.localStorage.getItem(localStorageKey);

  if (data !== null) {
    try {
      return JSON.parse(data).value;
    } catch (e) {
      // Invalid JSON, remove broken data
      window.localStorage.removeItem(localStorageKey);
      return null;
    }
  }

  return null;
}

export function setLocalStorage(key: LocalStorageKey, value: unknown): void {
  window.localStorage.setItem(prefixKey(key), JSON.stringify({ value }));
}

export function deleteLocalStorage(key: LocalStorageKey): void {
  window.localStorage.removeItem(prefixKey(key));
}

export function clearLocalStorage(): void {
  window.localStorage.clear();
}
