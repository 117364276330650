import { AspectRatio } from './ui';

type ImageSize = 's' | 'm' | 'l' | 'xl';

export interface ImageMapValues {
  SIXTEEN_BY_NINE: string | null | undefined;
  THIRTYTWO_BY_NINE: string | null | undefined;
  ONE_BY_ONE: string | null | undefined;
}

export class ImageMap {
  SIXTEEN_BY_NINE: string | undefined;
  THIRTYTWO_BY_NINE: string | undefined;
  ONE_BY_ONE: string | undefined;

  constructor(values?: ImageMapValues) {
    this.SIXTEEN_BY_NINE = values?.SIXTEEN_BY_NINE ?? undefined;
    this.THIRTYTWO_BY_NINE = values?.THIRTYTWO_BY_NINE ?? undefined;
    this.ONE_BY_ONE = values?.ONE_BY_ONE ?? undefined;
  }

  hasAnyImage(this: ImageMap): boolean {
    return Boolean(
      this.SIXTEEN_BY_NINE || this.THIRTYTWO_BY_NINE || this.ONE_BY_ONE,
    );
  }

  hasImage(this: ImageMap, aspectRatio: AspectRatio): boolean {
    switch (aspectRatio) {
      case AspectRatio.OneByOne:
        return Boolean(this.ONE_BY_ONE);
      case AspectRatio.SixteenByNine:
        return Boolean(this.SIXTEEN_BY_NINE);
      case AspectRatio.ThirtyTwoByNine:
        return Boolean(this.THIRTYTWO_BY_NINE);
      default:
        return false;
    }
  }

  withSizeQuery(
    this: ImageMap,
    aspectRatio: AspectRatio,
    size: ImageSize,
  ): string {
    let imgSrc;

    if (aspectRatio === AspectRatio.OneByOne) {
      imgSrc = this.ONE_BY_ONE;
    } else if (aspectRatio === AspectRatio.SixteenByNine) {
      imgSrc = this.SIXTEEN_BY_NINE;
    } else if (aspectRatio === AspectRatio.ThirtyTwoByNine) {
      imgSrc = this.THIRTYTWO_BY_NINE;
    } else {
      return '';
    }
    if (imgSrc === undefined) return '';

    try {
      // Append size using the URL Api, rather than string manipulation
      // as we cannot assume what query params might already exist in the imgSrc (which is an url string)
      const url = new URL(imgSrc);
      url.searchParams.append('size', size);

      return url.toString();
    } catch {
      // If we fail to append size query param, just return the original imgSrc
      return imgSrc;
    }
  }

  /**
   * Creates a srcset string for the given aspect ratio and size range.
   * @param aspectRatio
   * @param min size value to use as base resolution (1x pixel density ratio of the device)
   * @param max maximum size value to use as cap resolution
   * @returns srcset string (for example "https://example.com/image.jpg?s=1 1x, https://example.com/image.jpg?s=2 2x")
   */
  getScrSet(
    this: ImageMap,
    aspectRatio: AspectRatio,
    min: ImageSize,
    max: ImageSize,
  ): string {
    if (!this.hasImage(aspectRatio)) return '';
    if (min === max) return this.withSizeQuery(aspectRatio, min);

    const sizeStrings: ImageSize[] = ['s', 'm', 'l', 'xl'];
    const sizeValues: { [key in ImageSize]: number } = {
      s: 1,
      m: 2,
      l: 3,
      xl: 4,
    };

    const baseValue = sizeValues[min];
    const capValue = sizeValues[max];
    const srcset: string[] = [];

    sizeStrings.forEach((size) => {
      const sizeValue = sizeValues[size];
      if (sizeValue >= baseValue && sizeValue <= capValue) {
        let multiplier = sizeValue / baseValue;
        // Round to closest 0.5 or whole number
        multiplier = Math.ceil(multiplier * 2) / 2;

        srcset.push(`${this.withSizeQuery(aspectRatio, size)} ${multiplier}x`);
      }
    });

    return srcset.join(', ');
  }
}
