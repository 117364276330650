import { AppData, IAppFeedbackRequest, IAppResponse } from 'types/app';
import { ApiService } from './Api.service';

// API layer
export class AppAPIService {
  static async getApp(appAlias: string): Promise<IAppResponse> {
    return ApiService.fetch(`apps?name=${appAlias}`);
  }

  static async sendAppFeedback(feedback: IAppFeedbackRequest): Promise<void> {
    return ApiService.fetch(`apps/feedbacks`, {
      method: 'POST',
      body: JSON.stringify(feedback),
    });
  }
}

// Dto layer
export class AppService {
  static async getApp(appAlias: string): Promise<AppData> {
    return AppAPIService.getApp(appAlias).then(
      (data: IAppResponse) => new AppData(data),
    );
  }

  static async sendAppFeedback(feedback: IAppFeedbackRequest): Promise<void> {
    return AppAPIService.sendAppFeedback(feedback);
  }
}
