import { Theme } from 'types/theme';

/** A very colorful theme meant to identify color implementation errors.
 * Also known as "Stars and rainbows in Dantes Infreno".
 * Only theme with Font attributes, also used for debugging purpose. */
export const debugTheme: Theme = new Theme({
  v2: {
    colors: {
      background: '#FFEBEB',
      onBackground: '#FF0000',
      onBackgroundSubdued: '#FF00007A',
      onBackgroundBorder: '#FF00003D',
      backgroundDisabled: '#FF00000F',
      onBackgroundHeader: '#990000',

      surface: '#FFF8EB',
      onSurface: '#FFA500',
      onSurfaceSubdued: '#FFA5007A',
      onSurfaceBorder: '#FFA5003D',
      surfaceDisabled: '#FFA5000F',
      onSurfaceHeader: '#C27D00',

      primary: '#008000',
      primaryHover: '#006600',
      primaryActive: '#004D00',
      onPrimary: '#1AFF1A',
      primaryContainer: '#EBFFEB',
      onPrimaryContainer: '#00BD00',

      secondary: '#0000FF',
      secondaryHover: '#0000CC',
      secondaryActive: '#000099',
      onSecondary: '#00FFFF',
      secondaryContainer: '#E6F3FF',
      onSecondaryContainer: '#1F8FFF',

      accent: '#802AA8',
      accentHover: '#60207E',
      accentActive: '#411556',
      accentHoverAlpha: '#60207E1F',
      accentActiveAlpha: '#60207E29',
      onAccent: '#FF00FF',
      accentContainer: '#FBEEFB',
      onAccentContainer: '#DD69DD',
      info: '#0269FF',
      infoHover: '#0254CC',
      infoActive: '#013F99',
      onInfo: '#FFFFFF',
      infoContainer: '#E6F0FF',
      onInfoContainer: '#012A66',

      success: '#25AF72',
      successHover: '#1E8C5B',
      successActive: '#166944',
      onSuccess: '#FFFFFF',
      successContainer: '#E5F5EE',
      onSuccessContainer: '#0F462E',

      warning: '#EEB765',
      warningHover: '#BE9251',
      warningActive: '#8F6E3D',
      onWarning: '#FFFFFF',
      warningContainer: '#FDF6ED',
      onWarningContainer: '#5F4928',

      error: '#EE657A',
      errorHover: '#BE5162',
      errorActive: '#8F3D49',
      onError: '#FFFFFF',
      errorContainer: '#FDF0F2',
      onErrorContainer: '#5F2831',

      neutral: '#000000',
      neutralHover: '#2E2E2E',
      neutralActive: '#454545',
      onNeutral: '#FFFFFF',
      neutralContainer: '#F1F1F1',
      onNeutralContainer: '#000000',
    },
    typography: {
      fontBodyName: 'Roboto Flex',
      fontSubheaderName: 'Grandstander',
      fontHeaderName: 'Sora',
      fontStyleSheet: `
        /* latin-ext */
        @font-face {
          font-family: 'Roboto Flex';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau2OPFu02G5bac.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Roboto Flex';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau4OPFu02G5.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Roboto Flex';
          font-style: normal;
          font-weight: 500;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau4OPFu02G5.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Roboto Flex';
          font-style: normal;
          font-weight: 700;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau4OPFu02G5.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Sora';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/sora/v11/xMQ9uFFYT72X5wkB_18qmnndmSdSnh2BAfO5mnuyOo1lfiQwV6-xo6eeIw.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Sora';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/sora/v11/xMQ9uFFYT72X5wkB_18qmnndmSdSnh2BAfO5mnuyOo1lfiQwV6-xo6eeIw.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Sora';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/sora/v11/xMQ9uFFYT72X5wkB_18qmnndmSdSnh2BAfO5mnuyOo1lfiQwV6-xo6eeIw.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Grandstander';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/grandstander/v11/ga6KawtA-GpSsTWrnNHPCSImajC7XsdBMg.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Grandstander';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/grandstander/v11/ga6KawtA-GpSsTWrnNHPCSImajC7XsdBMg.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* latin */
        @font-face {
          font-family: 'Grandstander';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/grandstander/v11/ga6KawtA-GpSsTWrnNHPCSImajC7XsdBMg.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
    },
  },
});

export const darkTheme: Theme = new Theme({
  v2: {
    colors: {
      background: '#001731',
      onBackground: '#e5f1ff',
      onBackgroundSubdued: '#b8d6fa',
      onBackgroundBorder: '#b8d6fa3D',
      backgroundDisabled: '#e5f1ff0F',
      onBackgroundHeader: '#e5f1ff',

      surface: '#161d36',
      onSurface: '#ecf4fd',
      onSurfaceSubdued: '#c9d7e97A',
      onSurfaceBorder: '#c9d7e93D',
      surfaceDisabled: '#c9d7e90F',
      onSurfaceHeader: '#c9d7e9',

      primary: '#000c1a',
      primaryHover: '#000c1a',
      primaryActive: '#000c1a',
      onPrimary: '#e5f1ff',
      primaryContainer: '#e5f1ff',
      onPrimaryContainer: '#000c1a',

      secondary: '#ffb14d',
      secondaryHover: '#ff9b1d',
      secondaryActive: '#e68204',
      onSecondary: '#1f0c00',
      secondaryContainer: '#4e2a00',
      onSecondaryContainer: '#fff3db',

      accent: '#8a2948',
      accentHover: '#631c2e',
      accentActive: '#3d1019',
      accentHoverAlpha: '#8a29481F',
      accentActiveAlpha: '#8a294829',
      onAccent: '#ffe8ed',
      accentContainer: '#d6758a',
      onAccentContainer: '#1b0205',

      info: '#606986',
      infoHover: '#4b5169',
      infoActive: '#343a4c',
      onInfo: '#ecf1ff',
      infoContainer: '#4b5169',
      onInfoContainer: '#ecf1ff',

      success: '#4c6948',
      successHover: '#364c33',
      successActive: '#1f2e1d',
      onSuccess: '#eaf7ea',
      successContainer: '#364c33',
      onSuccessContainer: '#eaf7ea',

      warning: '#da7c0f',
      warningHover: '#a75f08',
      warningActive: '#784404',
      onWarning: '#1e0c00',
      warningContainer: '#783404',
      onWarningContainer: '#ffecdc',

      error: '#ca1b1b',
      errorHover: '#9e1314',
      errorActive: '#710c0e',
      onError: '#ffe5e5',
      errorContainer: '#9e1314',
      onErrorContainer: '#ffe5e5',

      neutral: '#343e4b',
      neutralHover: '#1e2530',
      neutralActive: '#070c18',
      onNeutral: '#e8f3ff',
      neutralContainer: '#343e4b',
      onNeutralContainer: '#e8f3ff',
    },
  },
});
