import { Auth, User as FirebaseUser, getAuth } from 'firebase/auth';
import {
  getAppAlias,
  locallyStoreAppDataValues,
} from 'helpers/app-helper/app-helper';
import {
  deleteLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage-helper/local-storage-helper';
import {
  addLogAttribute,
  removeLogAttribute,
} from 'helpers/log-helper/log-helper';
import { AppService } from 'services/App.service';
import { AuthService } from 'services/Auth.service';
import { IFirebaseConfig } from 'types/app';

/**
 * Add Firebase User accesstoken and userId to localStorage.
 * Also add the userId as a log attribute.
 */
export async function storeFirebaseUserCredentials(
  user: FirebaseUser,
): Promise<void> {
  const { uid } = user;
  const { token } = await user.getIdTokenResult();
  setLocalStorage('accessToken', token);
  setLocalStorage('userId', uid);
  addLogAttribute('userId', uid);
}

/**
 * Delete all references to the current user.
 */
export function deleteStoredUserInfo() {
  deleteLocalStorage('accessToken');
  deleteLocalStorage('userId');
  deleteLocalStorage('hasSeenSignIn');
  removeLogAttribute('guestId');
  removeLogAttribute('userId');
}

/**
 * Creates the link that will be used in the email for sign in.
 * Uses the current url to create a continue url, which is nested within the sign in link itself,
 * and used to resume the user's journey after the email sign in process is finished.
 *
 * @param currentUrl - the current url of the page
 * @param cartId - id of any current used cart, to be transferred to the user when signed in
 */
export function createEmailSignInLink(
  currentUrl: string,
  cartId: string | null,
): string {
  const { pathname, origin, search } = new URL(currentUrl);
  const appAlias = pathname.split('/')[1];

  // Store the current visited url to use when the confirm email process is finished
  const continueUrl = pathname + search;
  // Encode the continue url to be able to preserve it as a full seperate string
  const encodedContinueUrl = encodeURIComponent(continueUrl);

  // Create the actual link that will be used in the email
  if (cartId) {
    return `${origin}/${appAlias}/confirmEmail?signInWithCartId=${cartId}&continueUrl=${encodedContinueUrl}`;
  }
  return `${origin}/${appAlias}/confirmEmail?continueUrl=${encodedContinueUrl}`;
}

const reauthenticateApp = async (): Promise<Auth> => {
  let firebaseConfig: IFirebaseConfig | null =
    getLocalStorage<IFirebaseConfig>('firebaseConfig');

  if (!firebaseConfig) {
    const appAlias = getAppAlias();
    if (!appAlias) throw new Error('No app alias found.');

    // Fetch app data to get Firebase Config
    const appData = await AppService.getApp(appAlias);
    if (!appData.firebaseConfig) {
      throw new Error(
        `No Firebase Config found in App Response for ${appAlias}.`,
      );
    }

    firebaseConfig = appData.firebaseConfig;
    locallyStoreAppDataValues(appData);
  }

  const auth = getAuth();
  return auth;
};

export async function getRefreshToken(): Promise<string> {
  const auth = await reauthenticateApp();

  const currentUser = await AuthService.getCurrentUser(auth);
  if (!currentUser) {
    throw new Error('No Firebase User found while trying to refresh token.');
  }

  // Store and log current user attributes
  setLocalStorage('userId', currentUser.uid);
  addLogAttribute('userId', currentUser.uid);

  const { token } = await currentUser.getIdTokenResult(true);
  if (!token) throw new Error('No refresh token found.');

  return token;
}
