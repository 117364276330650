interface IThemeColorValues {
  primary: string;
  primaryHover: string;
  primaryActive: string;
  onPrimary: string;
  primaryContainer: string;
  onPrimaryContainer: string;

  secondary: string;
  secondaryHover: string;
  secondaryActive: string;
  onSecondary: string;
  secondaryContainer: string;
  onSecondaryContainer: string;

  accent: string;
  accentHover: string;
  accentHoverAlpha: string;
  accentActive: string;
  accentActiveAlpha: string;
  onAccent: string;
  accentContainer: string;
  onAccentContainer: string;

  background: string;
  onBackground: string;
  onBackgroundBorder: string;
  onBackgroundSubdued: string;
  backgroundDisabled: string;
  onBackgroundHeader: string;

  surface: string;
  onSurface: string;
  onSurfaceBorder: string;
  onSurfaceSubdued: string;
  surfaceDisabled: string;
  onSurfaceHeader: string;

  info: string;
  infoHover: string;
  infoActive: string;
  onInfo: string;
  infoContainer: string;
  onInfoContainer: string;

  success: string;
  successHover: string;
  successActive: string;
  onSuccess: string;
  successContainer: string;
  onSuccessContainer: string;

  warning: string;
  warningHover: string;
  warningActive: string;
  onWarning: string;
  warningContainer: string;
  onWarningContainer: string;

  error: string;
  errorHover: string;
  errorActive: string;
  onError: string;
  errorContainer: string;
  onErrorContainer: string;

  neutral: string;
  neutralHover: string;
  neutralActive: string;
  onNeutral: string;
  neutralContainer: string;
  onNeutralContainer: string;
}

interface IThemeTypographyValues {
  fontStyleSheet?: string;
  fontBodyName?: string;
  fontHeaderName?: string;
  fontSubheaderName?: string;
}

export interface IThemeValues {
  v2: {
    colors: IThemeColorValues | undefined;
    /** @ignore Not yet part of real API, only used for testing purposes in Mock Themes. */
    typography?: null | IThemeTypographyValues;
  };
}

class ThemeColors {
  primary: string;
  primaryHover: string;
  primaryActive: string;
  onPrimary: string;
  primaryContainer: string;
  onPrimaryContainer: string;

  secondary: string;
  secondaryHover: string;
  secondaryActive: string;
  onSecondary: string;
  secondaryContainer: string;
  onSecondaryContainer: string;

  accent: string;
  accentHover: string;
  accentHoverAlpha: string;
  accentActive: string;
  accentActiveAlpha: string;
  onAccent: string;
  accentContainer: string;
  onAccentContainer: string;

  background: string;
  onBackground: string;
  onBackgroundBorder: string;
  onBackgroundSubdued: string;
  backgroundDisabled: string;
  onBackgroundHeader: string;

  surface: string;
  onSurface: string;
  onSurfaceBorder: string;
  onSurfaceSubdued: string;
  surfaceDisabled: string;
  onSurfaceHeader: string;

  info: string;
  infoHover: string;
  infoActive: string;
  onInfo: string;
  infoContainer: string;
  onInfoContainer: string;

  success: string;
  successHover: string;
  successActive: string;
  onSuccess: string;
  successContainer: string;
  onSuccessContainer: string;

  warning: string;
  warningHover: string;
  warningActive: string;
  onWarning: string;
  warningContainer: string;
  onWarningContainer: string;

  error: string;
  errorHover: string;
  errorActive: string;
  onError: string;
  errorContainer: string;
  onErrorContainer: string;

  neutral: string;
  neutralHover: string;
  neutralActive: string;
  onNeutral: string;
  neutralContainer: string;
  onNeutralContainer: string;
  constructor(values?: IThemeColorValues) {
    this.primary = values?.primary ?? '';
    this.primaryHover = values?.primaryHover ?? '';
    this.primaryActive = values?.primaryActive ?? '';
    this.onPrimary = values?.onPrimary ?? '';
    this.primaryContainer = values?.primaryContainer ?? '';
    this.onPrimaryContainer = values?.onPrimaryContainer ?? '';

    this.secondary = values?.secondary ?? '';
    this.secondaryHover = values?.secondaryHover ?? '';
    this.secondaryActive = values?.secondaryActive ?? '';
    this.onSecondary = values?.onSecondary ?? '';
    this.secondaryContainer = values?.secondaryContainer ?? '';
    this.onSecondaryContainer = values?.onSecondaryContainer ?? '';

    this.accent = values?.accent ?? '';
    this.accentHover = values?.accentHover ?? '';
    this.accentHoverAlpha = values?.accentHoverAlpha ?? '';
    this.accentActive = values?.accentActive ?? '';
    this.accentActiveAlpha = values?.accentActiveAlpha ?? '';
    this.onAccent = values?.onAccent ?? '';
    this.accentContainer = values?.accentContainer ?? '';
    this.onAccentContainer = values?.onAccentContainer ?? '';

    this.background = values?.background ?? '';
    this.onBackground = values?.onBackground ?? '';
    this.onBackgroundBorder = values?.onBackgroundBorder ?? '';
    this.onBackgroundSubdued = values?.onBackgroundSubdued ?? '';
    this.backgroundDisabled = values?.backgroundDisabled ?? '';
    this.onBackgroundHeader = values?.onBackgroundHeader ?? '';

    this.surface = values?.surface ?? '';
    this.onSurface = values?.onSurface ?? '';
    this.surface = values?.surface ?? '';
    this.onSurface = values?.onSurface ?? '';
    this.onSurfaceBorder = values?.onSurfaceBorder ?? '';
    this.onSurfaceSubdued = values?.onSurfaceSubdued ?? '';
    this.surfaceDisabled = values?.surfaceDisabled ?? '';
    this.onSurfaceHeader = values?.onSurfaceHeader ?? '';

    this.info = values?.info ?? '';
    this.infoHover = values?.infoHover ?? '';
    this.infoActive = values?.infoActive ?? '';
    this.onInfo = values?.onInfo ?? '';
    this.infoContainer = values?.infoContainer ?? '';
    this.onInfoContainer = values?.onInfoContainer ?? '';

    this.success = values?.success ?? '';
    this.successHover = values?.successHover ?? '';
    this.successActive = values?.successActive ?? '';
    this.onSuccess = values?.onSuccess ?? '';
    this.successContainer = values?.successContainer ?? '';
    this.onSuccessContainer = values?.onSuccessContainer ?? '';

    this.warning = values?.warning ?? '';
    this.warningHover = values?.warningHover ?? '';
    this.warningActive = values?.warningActive ?? '';
    this.onWarning = values?.onWarning ?? '';
    this.warningContainer = values?.warningContainer ?? '';
    this.onWarningContainer = values?.onWarningContainer ?? '';

    this.error = values?.error ?? '';
    this.errorHover = values?.errorHover ?? '';
    this.errorActive = values?.errorActive ?? '';
    this.onError = values?.onError ?? '';
    this.errorContainer = values?.errorContainer ?? '';
    this.onErrorContainer = values?.onErrorContainer ?? '';

    this.neutral = values?.neutral ?? '';
    this.neutralHover = values?.neutralHover ?? '';
    this.neutralActive = values?.neutralActive ?? '';
    this.onNeutral = values?.onNeutral ?? '';
    this.neutralContainer = values?.neutralContainer ?? '';
    this.onNeutralContainer = values?.onNeutralContainer ?? '';
  }
}

/** @ignore Not yet part of real API, only used for testing purposes in Mock Themes. */
class ThemeTypography {
  fontStyleSheet: string | undefined;
  fontBodyName: string;
  fontHeaderName: string;
  fontSubheaderName: string;

  constructor(values?: IThemeTypographyValues | null) {
    this.fontStyleSheet = values?.fontStyleSheet ?? undefined;
    this.fontBodyName =
      values?.fontBodyName ?? 'Inter, Noto Sans, Roboto, Arial, sans-serif';
    this.fontSubheaderName =
      values?.fontSubheaderName ??
      'Inter, Noto Sans, Roboto, Arial, sans-serif';
    this.fontHeaderName =
      values?.fontHeaderName ?? 'Inter, Noto Sans, Roboto, Arial, sans-serif';
  }
}

type V2Theme = {
  colors: ThemeColors | undefined;
  /** @ignore Not yet part of real API, only used internally for testing purposes. */
  typography?: ThemeTypography;
};

export class Theme {
  v2: V2Theme;
  constructor(values?: IThemeValues) {
    this.v2 = {
      colors: values?.v2.colors
        ? new ThemeColors(values?.v2.colors)
        : undefined,
      typography: values?.v2?.typography
        ? new ThemeTypography(values?.v2?.typography)
        : undefined,
    };
  }
}

export enum TestThemeName {
  /** A theme for indicating lack of real theme. Color palette is greyscale. */
  Fallback = 'fallback',
  /** Also goes by the name "Stars and rainbows in Dantes Infreno" */
  Debug = 'debug',
  /** Dark debug theme, also goes by the name "Cherry Noir". */
  Dark = 'dark',
}
