import { getAppAlias } from 'helpers/app-helper/app-helper';

function getPrefix(): string | undefined {
  const appAlias = getAppAlias();
  return appAlias;
}

export function prefixKey(plainKey: string): string {
  const prefix = getPrefix();
  if (prefix) {
    return `[${prefix}]${plainKey}`;
  }

  return plainKey;
}
