import { useState } from 'react';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';

interface IConfirmDialog {
  isOpen: boolean;
  /** Title of the dialog */
  title: string;
  /** Body of the dialog */
  message?: string | undefined;
  /** Text for the primary button. */
  primaryButtonText: string | undefined;
  /** Text for optional secondary button */
  secondaryButtonText?: string | undefined;
  /** Function to call when user clicks on confirm button */
  primaryAction: () => void | Promise<void>;
  /** Function to call when user clicks on close button, should only close dialog. */
  onClose: () => void;
  /** Function to call when user clicks on secondary action button. */
  secondaryAction?: () => void;
  /** Show a close button for dialog. Defaults to true. */
  showCloseButton?: boolean;
}

function ConfirmationDialog({
  title,
  message,
  primaryButtonText,
  secondaryButtonText,
  primaryAction,
  secondaryAction,
  onClose,
  isOpen,
  showCloseButton = true,
}: IConfirmDialog) {
  const [isAwaitingPrimaryAction, setIsAwaitingPrimaryAction] = useState(false);

  const onClickPrimaryAction = async () => {
    setIsAwaitingPrimaryAction(true);
    await primaryAction();
    setIsAwaitingPrimaryAction(false);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalHeader
        p={ThemeSpaceVariable.Medium}
        display="flex"
        justifyContent="space-between"
      >
        {title}
        {showCloseButton && <ModalCloseButton />}
      </ModalHeader>
      <ModalBody px={ThemeSpaceVariable.Medium}>
        <Text fontSize={ThemeFontSizeVariable.Medium}>{message}</Text>
      </ModalBody>
      <ModalFooter
        pt={ThemeSpaceVariable.Medium}
        px={ThemeSpaceVariable.Medium}
        pb="calc(1em + env(safe-area-inset-bottom))"
        display="flex"
        justifyContent="flex-end"
      >
        <Stack direction="row" spacing={ThemeSpaceVariable.Medium}>
          {secondaryAction && (
            <Button
              size={{ base: 'lg', md: 'md' }}
              variant="ghost"
              onClick={secondaryAction}
            >
              <Text
                color={ThemeColorVariable.OnSurface}
                fontWeight="inherit"
                fontFamily="inherit"
                fontSize={{
                  base: ThemeFontSizeVariable.Large,
                  md: ThemeFontSizeVariable.Medium,
                }}
              >
                {secondaryButtonText}
              </Text>
            </Button>
          )}
          <Button
            size={{ base: 'lg', md: 'md' }}
            variant="primary"
            isLoading={isAwaitingPrimaryAction}
            onClick={onClickPrimaryAction}
          >
            <span>{primaryButtonText}</span>
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationDialog;
