import { createContext, useContext } from 'react';
import { AppData } from 'types/app';

interface IAppStore {
  /**
   * BSTL app data
   */
  readonly appData: AppData | undefined;
}

export const AppStore = createContext<IAppStore>({
  appData: undefined,
});

export const useAppStore = (): IAppStore => useContext(AppStore);
