import { AppData } from 'types/app';
import config, { EEnvironment } from 'config/app.config';
import { getCookieConsent } from 'helpers/cookie-helper/cookie-helper';
import { getTestTheme } from 'helpers/theme-helper/theme-helper';
import { AspectRatio } from 'types/ui';
import { Theme } from 'types/theme';
import { fallbackTheme } from '../../fallbackTheme';

export const setHTMLHeadAppProperties = (appData: AppData) => {
  document.title = appData.appName;

  // Favicon image image exists in appData, and no favicon is yet to be found in header
  if (appData.icon.ONE_BY_ONE && !document.getElementById('favicon')) {
    // Create a favicon link and append to our document head
    const favIconLink = document.createElement('link');
    favIconLink.href = appData.icon.withSizeQuery(AspectRatio.OneByOne, 's');
    favIconLink.id = 'favicon';
    favIconLink.rel = 'icon';
    document.head.appendChild(favIconLink);
  }
  if (appData.theme.v2.colors?.primary) {
    // Add a theme color meta tag with the app primary color
    const themeColorMetaTag = document.createElement('meta');
    themeColorMetaTag.name = 'theme-color';
    themeColorMetaTag.content = appData.theme.v2.colors.primary;
    document.head.appendChild(themeColorMetaTag);
  }
};

export const getInitialCrashReportingSetting = (): boolean => {
  // Always report crashes from these internal environments
  if (
    config.environment === EEnvironment.stage ||
    config.environment === EEnvironment.demo ||
    config.environment === EEnvironment.dev
  ) {
    return true;
  }
  // Never report crashes while working in local dev environment
  if (config.environment === EEnvironment.devLocal) return false;

  // Else, and for real users - base initial value on cookie consent
  if (!getCookieConsent()?.analytics) return false;

  // Fallback to enable crash reporting
  return true;
};

export function getTheme(appData?: AppData): Theme | undefined {
  const testTheme = getTestTheme();
  const hasRealTheme = Boolean(appData?.theme);
  const isProd = config.environment === EEnvironment.prod;

  switch (true) {
    case Boolean(testTheme) && !isProd:
      // Test theme is defined, use unless in prod
      return testTheme;
    case hasRealTheme:
      return appData?.theme;
    default:
      return fallbackTheme;
  }
}
