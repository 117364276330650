import StyledContainer from './Container.styles';

interface IContainer {
  /** Applies flex to container and centers content along x- and y-axis. */
  center?: boolean;
  children: React.ReactNode;
}

function Container({ children, center }: IContainer) {
  return <StyledContainer center={center}>{children}</StyledContainer>;
}

export default Container;
