import { RouteObject } from 'react-router-dom';
import RootLayout from '../rootLayout';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: ':appAlias/appredirectinfo',
        async lazy() {
          const AppRedirectInfoRoute = await import(
            './app-redirect-info-route/AppRedirectInfoRoute'
          );
          return { Component: AppRedirectInfoRoute.default };
        },
      },
      {
        path: ':appAlias',
        async lazy() {
          const PlacesRoute = await import('./places-route/PlacesRoute');
          return { Component: PlacesRoute.default };
        },
      },
      {
        path: ':appAlias/links/:linkId',
        async lazy() {
          const LinksRoute = await import('./links-route/LinksRoute');
          return { Component: LinksRoute.default };
        },
      },
      {
        path: ':appAlias/cookie-policy',
        async lazy() {
          const CookiePolicyRoute = await import(
            './cookie-policy-route/CookiePolicyRoute'
          );
          return { Component: CookiePolicyRoute.default };
        },
      },
      {
        path: ':appAlias/cookie-list',
        async lazy() {
          const CookieListRoute = await import(
            './cookie-list-route/CookieListRoute'
          );
          return { Component: CookieListRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId/menus',
        async lazy() {
          const MenusRoute = await import('./menus-route/MenusRoute');
          return { Component: MenusRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId',
        async lazy() {
          const PlaceRoute = await import('./place-route/PlaceRoute');
          return { Component: PlaceRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId/:menuId',
        async lazy() {
          const MenuRoute = await import('./menu-route/MenuRoute');
          return { Component: MenuRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId/:menuId/checkout',
        async lazy() {
          const CheckoutRoute = await import('./checkout-route/CheckoutRoute');
          return { Component: CheckoutRoute.default };
        },
      },
      {
        path: ':appAlias/checkout/:orderId',
        async lazy() {
          const OrderConfirmationRoute = await import(
            './order-confirmation-route/OrderConfirmationRoute'
          );
          return { Component: OrderConfirmationRoute.default };
        },
      },
      {
        path: ':appAlias/confirmEmail',
        async lazy() {
          const ConfirmEmailRoute = await import(
            './confirm-email/ConfirmEmailRoute'
          );
          return { Component: ConfirmEmailRoute.default };
        },
      },
    ],
  },
];
