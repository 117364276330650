/** Note: Original values from response holds native app specific features as well,
 * but they don't concern this application, so we will not include them here. */
export interface IAppFeaturesValues {
  general: IAppGeneralFeatures;
}

export type GuestType = 'REGISTERED_AND_ANONYMOUS' | 'REGISTERED' | 'ANONYMOUS';

export interface IAppGeneralFeatures {
  checkoutMessageEnabled?: boolean;
  tipEnabled?: boolean;
  showMenuSectionList?: boolean;
  guestType?: GuestType;
  b2bEnabled?: boolean;
  b2bEmailAddress?: string;
  sendFeedbackEnabled?: boolean;
}

/** Used to create one single flattened source of all feature flags applied to web. */
export class AppFeatures {
  checkoutMessageEnabled: boolean;
  tipEnabled: boolean;
  showMenuSectionList: boolean;
  guestType: GuestType;
  b2bEnabled: boolean;
  b2bEmailAddress: string | undefined;
  sendFeedbackEnabled: boolean;

  constructor(values?: IAppGeneralFeatures) {
    this.checkoutMessageEnabled = values?.checkoutMessageEnabled ?? false;
    this.tipEnabled = values?.tipEnabled ?? false;
    this.showMenuSectionList = values?.showMenuSectionList ?? false;
    this.guestType = values?.guestType ?? 'ANONYMOUS';
    this.b2bEnabled = values?.b2bEnabled ?? false;
    this.b2bEmailAddress = values?.b2bEmailAddress ?? undefined;
    this.sendFeedbackEnabled = values?.sendFeedbackEnabled ?? false;
  }
}
