import { useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@caspeco/casper-ui-library.base-ui.theme';
import { ConfirmationDialogProvider } from 'components/confirm-dialog/ConfirmDialog.context';
import { CookieProvider } from 'store/cookieModalStore';
import { AppStore } from 'store/appStore';
import { AuthProvider, IAuthStore } from 'store/authStore';
import { BSTLUserProvider } from 'store/bstlUserStore';
import { routes } from 'routes/routes';
import { SkeletonPageWrapper } from 'components/skeletons/SkeletonPageWrapper';
import PageNotFound from 'routes/page-not-found-route/components/PageNotFound';
import { useAppRequests } from './useAppRequests';
import { getTheme } from './App.helper';

const router = createBrowserRouter(routes, {
  basename: '/',
});

// Initial app requests with loading and error state could
// perhaps be handled further down in route structure in future
export default function App() {
  const { isLoading, appData, auth, error, firebaseApp, firebaseUser } =
    useAppRequests();
  const memoizedAppData = useMemo(() => ({ appData }), [appData]);
  const theme = getTheme(appData);
  const colors = theme?.v2?.colors;

  // Note: Fonts and Font Stylesheet are currently only available in test themes
  const fontStyleSheet = theme?.v2.typography?.fontStyleSheet;
  const fonts = theme?.v2.typography
    ? {
        header: theme.v2.typography.fontHeaderName,
        subheader: theme.v2.typography.fontSubheaderName,
        body: theme.v2.typography.fontBodyName,
      }
    : undefined;

  const initialAuthState: IAuthStore = {
    auth,
    firebaseApp,
    firebaseUser,
    isSignInModalOpen: false,
    isSignInModalManuallyOpened: false,
    isLoadingAuth: false,
  };

  if (isLoading) {
    return (
      <ThemeProvider
        colors={colors}
        fonts={fonts}
        fontStyleSheet={fontStyleSheet}
      >
        <SkeletonPageWrapper />
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider
        colors={colors}
        fonts={fonts}
        fontStyleSheet={fontStyleSheet}
      >
        <PageNotFound />
      </ThemeProvider>
    );
  }

  return (
    <AppStore.Provider value={memoizedAppData}>
      <ThemeProvider
        colors={colors}
        fonts={fonts}
        fontStyleSheet={fontStyleSheet}
      >
        <AuthProvider values={initialAuthState}>
          <BSTLUserProvider>
            <ConfirmationDialogProvider>
              <CookieProvider>
                <RouterProvider router={router} />
              </CookieProvider>
            </ConfirmationDialogProvider>
          </BSTLUserProvider>
        </AuthProvider>
      </ThemeProvider>
    </AppStore.Provider>
  );
}
