import { createContext, useContext, useMemo, useReducer } from 'react';
import { FirebaseApp } from '@firebase/app';
import {
  ActionCodeSettings,
  Auth,
  User as FirebaseUser,
  getAuth,
} from 'firebase/auth';
import { AuthService } from 'services/Auth.service';
import { LogPrefix } from 'types/logging';
import {
  deleteLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage-helper/local-storage-helper';
import {
  createEmailSignInLink,
  deleteStoredUserInfo,
  storeFirebaseUserCredentials,
} from 'helpers/auth-helper/auth-helper';
import { logDebug, logError } from 'helpers/log-helper/log-helper';
import { getSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';

export interface IAuthStore {
  /**
   * Interface representing Firebase Auth service.
   */
  readonly auth: undefined | Auth;
  /**
   * Holds the initialization information for a collection of Firebase services.
   */
  readonly firebaseApp: undefined | FirebaseApp;
  /**
   * Current Firebase user if signed in.
   * If the value is null, there is no user signed in.
   */
  readonly firebaseUser: null | FirebaseUser;
  /**
   * Is modal for signing in open.
   */
  readonly isSignInModalOpen: boolean;
  /**
   * Is modal for sign in opened by user. False if modal is opened "automatically" or not opened at all.
   */
  readonly isSignInModalManuallyOpened: boolean;
  /**
   * Is currently loading authentication (can for example be true during sign in or sign out).
   */
  readonly isLoadingAuth: boolean;
}

interface IAuthAPI {
  /**
   * Sign out current Firebase User.
   */
  signOut: () => Promise<void>;
  /**
   * Creates an anonymous firebase user account and signs in as this user.
   */
  signInAnonymously: () => Promise<void>;
  /**
   * Sends a sign in link, when followed will sign in the user with the given email.
   * @param email the email adress that should be receiving the sign in link
   * @param url a specific destination url to redirect to after sign in,
   * if not provided the current url will be used
   */
  sendSignInLink: (email: string, url?: string) => Promise<void>;
  /**
   * Sends a sign in link in the context of already having sent one previously,
   * when followed will sign in the user with the given email.
   * @param email the email adress that should be receiving the sign in link
   */
  reSendSignInLink: (email: string) => Promise<void>;
  /**
   * Signs in with email link.
   * @param link the full url of the sign in link
   */
  signInWithEmailLink: (link: string, email: string) => Promise<FirebaseUser>;
  /**
   * Sign in with Google. Redirects the user to Google Sign in page.
   */
  signInWithGoogle: () => Promise<FirebaseUser>;
  /**
   * Sign in with Apple. Redirects the user to Apple Sign in.
   */
  signInWithApple: () => Promise<FirebaseUser>;
  /**
   * Set open state for sign in modal.
   * @param isManuallyOpened should be true if the user opened the modal themselves (clicked "Sign in").
   */
  setIsSignInModalOpen: (isOpen: boolean, isManuallyOpened?: boolean) => void;
  /**
   * Deletes the current user.
   */
  deleteUser: () => Promise<void>;
}

enum ActionType {
  SET_AUTH,
  SET_CURRENT_USER,
  SET_FIREBASE_APP,
  SET_IS_SIGN_IN_MODAL_OPEN,
  SET_IS_LOADING_AUTH,
}

type Action =
  | { type: ActionType.SET_AUTH; payload: Auth | undefined }
  | { type: ActionType.SET_CURRENT_USER; payload: FirebaseUser | null }
  | { type: ActionType.SET_FIREBASE_APP; payload: FirebaseApp | undefined }
  | { type: ActionType.SET_IS_LOADING_AUTH; payload: boolean }
  | {
      type: ActionType.SET_IS_SIGN_IN_MODAL_OPEN;
      payload: { isOpen: boolean; isManuallyOpened: boolean };
    };

const AuthStore = createContext<IAuthStore>({} as IAuthStore);
const AuthAPI = createContext<IAuthAPI>({} as IAuthAPI);

const initialState: IAuthStore = {
  auth: undefined,
  firebaseApp: undefined,
  firebaseUser: null,
  isSignInModalOpen: false,
  isSignInModalManuallyOpened: false,
  isLoadingAuth: false,
};

const reducer = (state: IAuthStore, action: Action): IAuthStore => {
  switch (action.type) {
    case ActionType.SET_AUTH:
      return {
        ...state,
        auth: action.payload,
        isLoadingAuth: false,
      };
    case ActionType.SET_CURRENT_USER:
      return {
        ...state,
        firebaseUser: action.payload,
        isLoadingAuth: false,
      };
    case ActionType.SET_FIREBASE_APP:
      return {
        ...state,
        firebaseApp: action.payload,
        isLoadingAuth: false,
      };
    case ActionType.SET_IS_SIGN_IN_MODAL_OPEN:
      return {
        ...state,
        isSignInModalOpen: action.payload.isOpen,
        isSignInModalManuallyOpened: action.payload.isManuallyOpened,
      };
    case ActionType.SET_IS_LOADING_AUTH:
      return {
        ...state,
        isLoadingAuth: action.payload,
      };
    default:
      return state;
  }
};

export function AuthProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  values?: IAuthStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IAuthAPI = useMemo(() => {
    const signOut = async () => {
      try {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: true });
        const auth = state.auth ?? getAuth();
        await AuthService.signOut(auth);
        logDebug(LogPrefix.Auth, `User signed out`);
        deleteStoredUserInfo();
        dispatch({ type: ActionType.SET_CURRENT_USER, payload: null });
      } catch (error) {
        logError(LogPrefix.Auth, error, `Could not sign out User`);
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: false });
        throw error;
      }
    };

    const signInAnonymously = async () => {
      try {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: true });
        const auth = state.auth ?? getAuth();
        const { user } = await AuthService.signInAnonymously(auth);
        await storeFirebaseUserCredentials(user);

        dispatch({ type: ActionType.SET_CURRENT_USER, payload: user });
      } catch (error) {
        logError(LogPrefix.Auth, error, 'Could not sign in user anonymously');
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: false });
        throw error;
      }
    };

    const deleteUser = async () => {
      try {
        const auth = state.auth ?? getAuth();
        await AuthService.deleteUser(auth);
        logDebug(LogPrefix.Auth, 'Firebase user deleted');
        deleteStoredUserInfo();
        dispatch({ type: ActionType.SET_CURRENT_USER, payload: null });
      } catch (error) {
        logError(LogPrefix.Auth, error, 'Could not delete Firebase user');
        throw error;
      }
    };

    const sendSignInLink = async (email: string, url?: string) => {
      try {
        const cartId = getSessionStorage<string>('cartId');

        const actionCodeSettings: ActionCodeSettings = {
          url: createEmailSignInLink(url ?? window.location.href, cartId),
          handleCodeInApp: true,
        };

        const auth = state.auth ?? getAuth();
        await AuthService.sendSignInLink(auth, email, actionCodeSettings);

        logDebug(LogPrefix.Auth, 'Sending sign in link to given email');

        // Store the sign in link info locally to enable the user to sign in
        // in another window, we will remove it after successfull sign in
        setLocalStorage('signInLinkEmail', email);
      } catch (error) {
        logError(
          LogPrefix.Auth,
          error,
          'Could not send sign in link to given email',
        );
        throw error;
      }
    };

    const reSendSignInLink = async (email: string) => {
      try {
        const actionCodeSettings: ActionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true,
        };

        const auth = state.auth ?? getAuth();
        await AuthService.sendSignInLink(auth, email, actionCodeSettings);

        logDebug(LogPrefix.Auth, `Re-sending sign in link to given email`);

        // Store the sign in link info locally to enable the user to sign in
        // in another window, we will remove it after successfull sign in
        setLocalStorage('signInLinkEmail', email);
      } catch (error) {
        logError(
          LogPrefix.Auth,
          error,
          'Could not re-send sign in link to given email',
        );
        throw error;
      }
    };

    const signInWithEmailLink = async (link: string, email: string) => {
      // Clean up temporary local storage entries associated with user and sign in
      // we want to remove this reference no matter if the authentication is successful or not
      deleteLocalStorage('signInLinkEmail');

      try {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: true });
        const auth = state.auth ?? getAuth();
        const { user } = await AuthService.signInWithEmailLink(
          auth,
          email,
          link,
        );

        await storeFirebaseUserCredentials(user);
        logDebug(LogPrefix.Auth, 'Successfully signed in user with email link');
        dispatch({ type: ActionType.SET_CURRENT_USER, payload: user });
        return user;
      } catch (error) {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: false });
        logError(LogPrefix.Auth, error, 'Could not sign in with email link');
        throw error;
      }
    };

    const signInWithGoogle = async () => {
      try {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: true });
        const auth = state.auth ?? getAuth();
        logDebug(LogPrefix.Auth, 'User started sign in with Google');
        const { user } = await AuthService.signInWithGoogle(auth);

        await storeFirebaseUserCredentials(user);
        logDebug(LogPrefix.Auth, 'User finished sign in with Google');
        dispatch({ type: ActionType.SET_CURRENT_USER, payload: user });
        return user;
      } catch (error) {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: false });
        logError(LogPrefix.Auth, error, 'Could not sign in with Google');
        throw error;
      }
    };

    const signInWithApple = async () => {
      try {
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: true });
        const auth = state.auth ?? getAuth();
        logDebug(LogPrefix.Auth, 'User started sign in with Apple');
        const { user } = await AuthService.signInWithApple(auth);

        await storeFirebaseUserCredentials(user);
        logDebug(LogPrefix.Auth, 'User finished sign in with Apple');
        dispatch({ type: ActionType.SET_CURRENT_USER, payload: user });
        return user;
      } catch (error) {
        logError(LogPrefix.Auth, error, 'Could not sign in with Apple');
        dispatch({ type: ActionType.SET_IS_LOADING_AUTH, payload: false });
        throw error;
      }
    };

    const setIsSignInModalOpen = (
      isOpen: boolean,
      isManuallyOpened: boolean = false,
    ) => {
      dispatch({
        type: ActionType.SET_IS_SIGN_IN_MODAL_OPEN,
        payload: { isOpen, isManuallyOpened },
      });
    };

    return {
      signOut,
      signInAnonymously,
      sendSignInLink,
      signInWithEmailLink,
      reSendSignInLink,
      signInWithGoogle,
      signInWithApple,
      setIsSignInModalOpen,
      deleteUser,
    };
  }, []);

  return (
    <AuthAPI.Provider value={api}>
      <AuthStore.Provider value={state}>{children}</AuthStore.Provider>
    </AuthAPI.Provider>
  );
}

export const useAuthStore = (): IAuthStore => useContext(AuthStore);
export const useAuthAPI = (): IAuthAPI => useContext(AuthAPI);
