import { SupportedLocale } from './locale';

export interface IBSTLUserResponse {
  userId?: string;
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  locale: string;
  guestId: string;
}

export class BSTLUser {
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  locale: SupportedLocale;
  userId: string;
  guestId: string;

  constructor(values: IBSTLUserResponse) {
    this.userId = values?.userId ?? '';
    this.name = values?.name ?? '';
    this.emailAddress = values?.emailAddress ?? '';
    this.phoneNumber = values?.phoneNumber ?? '';
    this.locale = (values?.locale as SupportedLocale) ?? SupportedLocale.EN;
    this.guestId = values?.guestId;
  }
}

export interface IBSTLUserRequestValues {
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  locale: string;
}
