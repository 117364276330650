import { FirebaseApp, initializeApp } from '@firebase/app';
import {
  getAuth,
  signInAnonymously,
  setPersistence,
  browserLocalPersistence,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
  ActionCodeSettings,
  Auth,
  User,
  GoogleAuthProvider,
  UserCredential,
  getRedirectResult,
  OAuthProvider,
  OAuthCredential,
  signInWithPopup,
  deleteUser as deleteFirebaseUser,
} from 'firebase/auth';
import { getLocaleFromCurrentLang } from 'helpers/locale-helper/locale-helper';
import { IFirebaseConfig } from 'types/app';

export class AuthService {
  static async initializeFirebase(
    firebaseConfig: IFirebaseConfig,
  ): Promise<{ auth: Auth; firebaseApp: FirebaseApp }> {
    // Note: The firebase initialization should only be called once in our application
    const firebaseApp = initializeApp(firebaseConfig);
    const auth = getAuth(firebaseApp);

    return { auth, firebaseApp };
  }

  static async signInAnonymously(auth: Auth): Promise<UserCredential> {
    return setPersistence(auth, browserLocalPersistence).then(() =>
      signInAnonymously(auth),
    );
  }

  static async signOut(auth: Auth): Promise<void> {
    return signOut(auth);
  }

  static async sendSignInLink(
    auth: Auth,
    email: string,
    actionCodeSettings: ActionCodeSettings,
  ): Promise<void> {
    return sendSignInLinkToEmail(auth, email, actionCodeSettings);
  }

  static async getCurrentUser(auth: Auth): Promise<User | null> {
    let userLoaded = false;
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(auth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true;
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  static async signInWithGoogle(auth: Auth): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }

  static async signInWithApple(auth: Auth): Promise<UserCredential> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      // Localize the Apple authentication screen
      locale: getLocaleFromCurrentLang(),
    });
    return signInWithPopup(auth, provider);
  }

  static async signInWithEmailLink(
    auth: Auth,
    email: string,
    link: string,
  ): Promise<UserCredential> {
    return setPersistence(auth, browserLocalPersistence).then(() =>
      signInWithEmailLink(auth, email, link),
    );
  }

  static async getRedirectResult(auth: Auth): Promise<UserCredential | null> {
    return getRedirectResult(auth);
  }

  static async getAppleSignInRedirectResult(
    auth: Auth,
  ): Promise<OAuthCredential | null> {
    return getRedirectResult(auth).then((result) => {
      if (!result) return null;
      return OAuthProvider.credentialFromResult(result);
    });
  }

  static async deleteUser(auth: Auth): Promise<void> {
    const { currentUser } = auth;
    if (currentUser) {
      return deleteFirebaseUser(currentUser);
    }
    return Promise.resolve();
  }
}
