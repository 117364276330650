import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { darkTheme, debugTheme } from 'test/mocks/mockThemes';
import { TestThemeName, Theme } from 'types/theme';
import { fallbackTheme } from '../../fallbackTheme';

/**
 * If a test theme name is stored locally, will return the
 * corresponding mock theme data. Otherwise undefined.
 *
 * @returns Theme object or undefined.
 * Used for testing purposes, see also ThemeModal.tsx
 */
export const getTestTheme = (): Theme | undefined => {
  const testThemeName = getLocalStorage<TestThemeName>('testThemeName');

  switch (testThemeName) {
    case TestThemeName.Debug:
      return debugTheme;
    case TestThemeName.Fallback:
      return fallbackTheme;
    case TestThemeName.Dark:
      return darkTheme;
    default:
      return undefined;
  }
};
