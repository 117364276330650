export enum EEnvironment {
  prod = 'prod',
  dev = 'dev',
  devLocal = 'dev-local',
  local = 'local',
  stage = 'stage',
  demo = 'demo',
  msw = 'msw',
  mswCI = 'msw-ci',
}

interface IEnvironment {
  baseUrl: string;
  i18nUrl: string;
  environment: EEnvironment | null;
}

function getEnvironment(name: string): EEnvironment | null {
  switch (name) {
    case 'prod':
      return EEnvironment.prod;
    case 'dev':
      return EEnvironment.dev;
    case 'dev-local':
      return EEnvironment.devLocal;
    case 'stage':
      return EEnvironment.stage;
    case 'local':
      return EEnvironment.local;
    case 'demo':
      return EEnvironment.demo;
    case 'msw':
      return EEnvironment.msw;
    case 'msw-ci':
      return EEnvironment.mswCI;
    default:
      return null;
  }
}

export const config: IEnvironment = {
  baseUrl: import.meta.env.VITE_APP_BASE_URL ?? '',
  i18nUrl: import.meta.env.VITE_APP_I18N_URL ?? '',
  environment: getEnvironment(import.meta.env.VITE_APP_ENV_NAME ?? ''),
};

export default config;
