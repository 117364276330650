import { useTranslation } from 'react-i18next';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Button } from '@caspeco/casper-ui-library.components.button';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';

interface INotFound {
  /** Custom margin top. Otherwise defaults to a set of responsive values. */
  mt?: string;
  /** Specific title describing what entity was not found. Defaults to generic not found title. */
  title?: string;
  /** Specific description of what entity was not found. Defaults to generic not found message. */
  description?: string;
  /** Variant, base this on what background the NotFound appears.
   * Controls text and icon color. Defaults to onBackground. */
  variant?: 'surface' | 'background';
  /** Show a primary action button for reloading page below title and description. Defaults to false.
   * Use buttonTitle and buttonOnClick to customize the button. */
  showWithButton?: boolean;
  /** Shows as text on a primary action button below title and description. Defaults to 'action_try_again' */
  buttonTitle?: string;
  /** Called when clicking the primary action button. Defaults to calling window.location.reload. */
  buttonOnClick?: () => void;
  /** Icon to show above title. Defaults to "face-frown-slight" */
  icon?: Icons;
}

/**
 * Custom ui to be used when some main enitity could not be fetched or is absent in other ways.
 * Below the icon the title + description is displayed, as well as a button (optional) for reloading page.
 */
function NotFound({
  mt,
  variant = 'background',
  title,
  icon = Icons.SlightlySad,
  description,
  buttonTitle,
  buttonOnClick,
  showWithButton = false,
}: INotFound) {
  const { t } = useTranslation();

  const onClick = () => {
    if (buttonOnClick) {
      buttonOnClick();
    } else {
      window.location.reload();
    }
  };

  const titleColor =
    variant === 'background'
      ? ThemeColorVariable.OnBackgroundHeader
      : ThemeColorVariable.OnSurfaceHeader;
  const descriptionColor =
    variant === 'background'
      ? ThemeColorVariable.OnBackground
      : ThemeColorVariable.OnSurface;
  const iconColor =
    variant === 'background'
      ? ThemeColorVariable.OnBackgroundBorder
      : ThemeColorVariable.OnSurfaceBorder;

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      mt={mt ?? { base: '70px', md: '104px' }}
    >
      <Icon
        icon={icon}
        color={iconColor}
        size={{
          base: ThemeIconSizeVariable.X2Large,
          lg: ThemeIconSizeVariable.X3Large,
        }}
        mb={ThemeSpaceVariable.Medium}
      />
      <Text
        fontSize={{
          base: ThemeFontSizeVariable.Large,
          md: ThemeFontSizeVariable.XLarge,
        }}
        color={titleColor}
        align="center"
        fontWeight="medium"
        fontFamily={ThemeFontVariable.SubHeader}
      >
        {title ?? t('error_generic')}
      </Text>
      <Text
        fontSize={ThemeFontSizeVariable.Medium}
        color={descriptionColor}
        align="center"
        mb={ThemeSpaceVariable.Medium}
        maxW={{ base: '90%', md: '414px' }}
      >
        {description ?? t('error_get_page')}
      </Text>
      {showWithButton && (
        <Button variant="primary" onClick={onClick} size="md">
          {buttonTitle ?? t('action_try_again')}
        </Button>
      )}
    </Flex>
  );
}

export default NotFound;
