import { getBSTLApiErrorMessage } from 'helpers/error-code-helper/bstl-api-error-helper';

export interface IBSTLApiErrorResponse {
  errors: IBSTLApiError[];
}

interface IBSTLApiError {
  key: BSTLErrorCode;
  message: string;
}

/**
 * Custom errors thrown in client.
 */
export enum BSTLClientErrorCode {
  SIGN_IN_REQUIRED = 'SIGN_IN_REQUIRED',
  NO_APP_DATA = 'NO_APP_DATA',
}

export type BSTLErrorCode =
  | 'MENU_CLOSED'
  | 'PAYMENT_INITIALIZATION_NOT_POSSIBLE'
  | 'PAYMENT_FINALIZATION_NOT_POSSIBLE'
  | 'PAYMENT_PROVIDER_DOES_NOT_EXIST'
  | 'CHANNEL_CLOSED'
  | 'MAX_QUANTITY_EXCEEDED'
  | 'MAX_TIP_AMOUNT_EXCEEDED'
  | 'INVALID_READY_TIME'
  | 'CAMPAIGN_CODE_INVALID'
  | 'CAMPAIGN_CODE_ALREADY_USED'
  | 'CAMPAIGN_CODE_EXPIRED'
  | 'UNKNOWN_ERROR';

export class BSTLApiError extends Error {
  /** Orignal error message. */
  message: string;
  /** Error message translated to user friendly error. */
  translatedMessage: string;
  /** HTTP status code. */
  statusCode: number | undefined;
  /** Error code recieved from backend */
  key?: string;
  constructor(
    errors: IBSTLApiErrorResponse,
    statusCode: number,
    ...params: any
  ) {
    super(...params);
    // Maintains stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BSTLApiError);
    }
    this.key = errors?.errors ? errors.errors[0]?.key : '';
    this.statusCode = statusCode;
    // Custom error messages, both original and translated to user friendly message
    this.message = getBSTLApiErrorMessage(errors);
    this.translatedMessage = getBSTLApiErrorMessage(errors, true);
  }
}
