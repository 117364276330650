import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import NotFound from 'components/not-found';

export default function RootLayout() {
  const { t } = useTranslation();
  return (
    <ErrorBoundary
      fallback={
        <Box px={ThemeSpaceVariable.Medium}>
          <NotFound
            title={t('error_generic_reload_page')}
            description=""
            showWithButton
          />
        </Box>
      }
    >
      <Box
        as="main"
        bg={ThemeColorVariable.Background}
        flexGrow={1}
        flexShrink={0}
        flexBasis="auto"
      >
        <Outlet />
      </Box>
    </ErrorBoundary>
  );
}
