export enum BreakPoint {
  /** Small devices (landscape phones, 30em and up) */
  Small = '30em',
  /** Medium devices (tablets, 48em and up) */
  Medium = '48em',
  /** Large devices (desktops, 62em and up) */
  Large = '62em',
  /** Extra large devices (large desktops, 80em and up) */
  XLarge = '80em',
}

export enum AspectRatio {
  SixteenByNine = '16 / 9',
  OneByOne = '1 / 1',
  ThirtyTwoByNine = '32 / 9',
}
