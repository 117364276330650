import { createContext, useContext, useMemo, useState } from 'react';
import ConfirmationDialog from './ConfirmDialog';

interface IDialogConfig {
  /** The title of the dialog */
  title: string;
  /** The message to display in the dialog */
  message?: string;
  /** Text for the confirm button */
  confirmButtonText: string;
  /** Text for the dismiss button */
  dismissButtonText?: string;
  /** The callback to execute when the user clicks the confirm button */
  actionCallback?: (confirmed: boolean) => void;
  /** Show a close button for dialog. Defaults to true. */
  showCloseButton?: boolean;
}

interface IConfirmationDialogContext {
  /** The current dialog configuration */
  openDialog?: (content: IDialogConfig) => void;
}

const ConfirmationDialogContext = createContext<IConfirmationDialogContext>({});

function ConfirmationDialogProvider({ children }: any) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<IDialogConfig>(
    {} as IDialogConfig,
  );

  const openDialog = ({
    actionCallback,
    title,
    message,
    dismissButtonText,
    confirmButtonText,
    showCloseButton,
  }: IDialogConfig) => {
    setDialogOpen(true);

    setDialogConfig({
      title,
      message,
      confirmButtonText,
      dismissButtonText,
      actionCallback,
      showCloseButton,
    });
  };
  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({} as IDialogConfig);
  };

  const onConfirm = () => {
    resetDialog();

    if (dialogConfig.actionCallback) dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
  };

  const value = useMemo(() => ({ openDialog }), [openDialog]);

  return (
    <ConfirmationDialogContext.Provider value={value}>
      {dialogOpen && (
        <ConfirmationDialog
          isOpen={dialogOpen}
          primaryAction={onConfirm}
          showCloseButton={dialogConfig.showCloseButton}
          onClose={onDismiss}
          message={dialogConfig.message}
          title={dialogConfig.title}
          primaryButtonText={dialogConfig.confirmButtonText}
          secondaryButtonText={dialogConfig?.dismissButtonText}
          secondaryAction={
            dialogConfig?.dismissButtonText ? onDismiss : undefined
          }
        />
      )}
      {children}
    </ConfirmationDialogContext.Provider>
  );
}

const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmationDialog = (options: IDialogConfig) =>
    new Promise(() => {
      openDialog!({
        actionCallback: options.actionCallback,
        title: options.title,
        message: options.message,
        confirmButtonText: options.confirmButtonText,
        dismissButtonText: options.dismissButtonText,
        showCloseButton: options.showCloseButton,
      });
    });

  return { getConfirmationDialog };
};

export { ConfirmationDialogProvider, useConfirmationDialog };
